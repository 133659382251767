import { defineNuxtRouteMiddleware } from 'nuxt/app';
import { useFundStore } from '@stores/useFundStore';
import { FundPageStatuses } from '@graphql/enums/fundStatuses';
import { getFundPage } from '@helpers/fundPage';
import { useToast } from '@mogelijk-technologies/ui-library';

export default defineNuxtRouteMiddleware(async to => {
  const { currentFund } = toRefs(useFundStore());
  const { getFund, setCurrentFund } = useFundStore();

  if (currentFund.value === null || currentFund.value?.id !== to.params.id) {
    const fund = await getFund(to.params.id as string);

    if (fund) {
      setCurrentFund(fund);

      if (fund.page_status === FundPageStatuses.NOT_ALLOWED) {
        return navigateTo({ path: '/mogelijk-fonds' });
      }

      const path = getFundPage(fund.page_status);
      if (path) return navigateTo({ path: `/mogelijk-fonds/${to.params.id}/${path}` });

      useToast({
        message: 'Er is iets fout gegaan bij het ophalen van het fonds. Probeer het later opnieuw.',
        theme: 'error',
      });

      return navigateTo({ path: '/mogelijk-fonds' });
    }
  }
});
